import { NoSsr } from "@mui/base";
import { useTheme } from "@mui/material/styles";
import { usePrevious } from "@react-hookz/web";
import { useScroll } from "ahooks";
import { useAtomValue } from "jotai";
import type { PageProps } from "next";
import type { CSSProperties, FunctionComponent, ReactNode } from "react";
import { useMemo } from "react";

import { useResponsiveAdSlot } from "scmp-app/components/advertisement/hooks";
import { appBarScrollStickyAtom } from "scmp-app/components/app-bar/hooks/sticky/atoms";
import { HeaderBottomContainerElementID } from "scmp-app/pages/_app/consts";

import { appBarAtom } from "./atoms";
import { HideElementOnScrollEffectThreshold } from "./consts";
import { AppBarContextProvider } from "./contexts";
import { useAppBarSetStickyHeight } from "./hooks";
import {
  AdSlotContainer,
  Container,
  FullWidthBackground,
  HeaderBottomContainer,
  StyledHeader,
  StyledHomeHeader,
  StyledHomeMiniHeader,
  StyledPlusHeader,
  StyledPostiesBrochureHeader,
  StyledPostiesContentHeader,
  StyledStyleContentHeader,
} from "./styles";
import type { AppBarVariant } from "./types";

export type Props = {
  children?: ReactNode;
  className?: string;
  hideTargetChildren?: ReactNode;
  pageProps?: PageProps;
};

export const AppBar: FunctionComponent<Props> = ({ children, className, pageProps }) => {
  const { adSlotHeight, bindAdSlotContainer, responsiveAdSlot, stickyStyle } = useResponsiveAdSlot(
    pageProps?.appBarConfiguration,
  );

  const appBarValue = useAtomValue(appBarAtom);
  const { stickyScrollHeight = 0 } = useAtomValue(appBarScrollStickyAtom);

  const appBarVariant = useMemo<AppBarVariant>(
    () =>
      appBarValue?.scrollOverVariant ??
      pageProps?.appBarConfiguration?.variant ??
      "scmp/generic-light",
    [appBarValue?.scrollOverVariant, pageProps?.appBarConfiguration?.variant],
  );

  const appBarStickyScrollHeight = useMemo(
    // added 2 trying to avoid double menu when scrolling up/down, should find a better way.
    () =>
      appBarVariant === "scmp/magazines-post-magazine" ? 0 : stickyScrollHeight + adSlotHeight,
    [adSlotHeight, stickyScrollHeight, appBarVariant],
  );

  const hideElementOnScrollEffect = useStickyWithHideElementAfterScrollThreshold(
    HideElementOnScrollEffectThreshold.mobile,
    appBarStickyScrollHeight,
  );
  const stickyWithScrollAwayElement = useStickyWithScrollAwayElement(appBarStickyScrollHeight);

  const styles: CSSProperties = {
    ...(stickyStyle === "withHideElementAfterScrollThreshold"
      ? hideElementOnScrollEffect.targetContainerStyles
      : stickyWithScrollAwayElement.targetContainerStyles),
  };

  const { reference } = useAppBarSetStickyHeight();

  const homeHeaderConfiguration = {
    ...pageProps?.headerConfiguration,
    appBarStickyScrollHeight,
    appBarVariant,
  };

  const renderHeader = () => {
    switch (appBarVariant) {
      case "posties/generic":
        return <StyledPostiesContentHeader />;
      case "posties/brochure":
        return <StyledPostiesBrochureHeader />;
      case "scmp/magazines-style":
        const headerConfiguration = {
          ...pageProps?.headerConfiguration,
          appBarStickyScrollHeight,
          appBarVariant,
        };
        return <StyledStyleContentHeader headerConfiguration={headerConfiguration} />;
      case "scmp/home":
        return <StyledHomeHeader headerConfiguration={homeHeaderConfiguration} />;
      case "scmp/home-slim":
      case "scmp/magazines-post-magazine":
        return <StyledHomeMiniHeader headerConfiguration={homeHeaderConfiguration} />;
      case "scmp/plus":
      case "scmp/plus/learnmore":
        return <StyledPlusHeader headerConfiguration={homeHeaderConfiguration} />;
      default:
        return <StyledHeader headerConfiguration={pageProps?.headerConfiguration} />;
    }
  };

  const renderLayout = () => {
    switch (appBarVariant) {
      case "scmp/home":
        return (
          <>
            {renderHeader()}
            {children}
            <AdSlotContainer {...bindAdSlotContainer}>
              <NoSsr>{responsiveAdSlot}</NoSsr>
            </AdSlotContainer>
          </>
        );
      case "scmp/magazines-post-magazine":
        return (
          <>
            {children}
            {renderHeader()}
          </>
        );
      default:
        return (
          <>
            <AdSlotContainer {...bindAdSlotContainer}>
              <NoSsr>{responsiveAdSlot}</NoSsr>
            </AdSlotContainer>
            {renderHeader()}
            {children}
          </>
        );
    }
  };

  return (
    <AppBarContextProvider>
      <Container
        $appBarVariant={appBarVariant}
        className={className}
        ref={reference}
        style={{ ...styles }}
      >
        <FullWidthBackground />
        {renderLayout()}
        <HeaderBottomContainer id={HeaderBottomContainerElementID} />
      </Container>
    </AppBarContextProvider>
  );
};

AppBar.displayName = "AppBar";

export const useShouldHidden = (scrollThreshold: number) => {
  const position = useScroll();

  if (!position) {
    return null;
  }

  const defaultScrollOffsetY = position?.top ?? 0;

  const containerElementScrollOffsetY = defaultScrollOffsetY === 0 ? 0 : defaultScrollOffsetY;
  return containerElementScrollOffsetY > scrollThreshold;
};

// This effect will hide the element with transition after the scroll container scroll passes a threshold
const useStickyWithHideElementAfterScrollThreshold = (
  scrollThreshold: number,
  targetElementHeight: number,
) => {
  const theme = useTheme();
  const previousTargetElementHeight = usePrevious(targetElementHeight);

  const shouldHidden = useShouldHidden(scrollThreshold);
  const shouldPauseTransition = previousTargetElementHeight !== targetElementHeight;

  const targetContainerStyles: CSSProperties = {
    position: "sticky",
    transform: shouldHidden ? `translateY(-${targetElementHeight}px)` : "none",
    transition: shouldPauseTransition
      ? "none"
      : theme.transitions.create("transform", {
          duration: 50,
        }),
  };

  return {
    targetContainerStyles,
  };
};

// This effect will make the container sticky after the scroll away element is complete scroll
// passed in the scroll container
const useStickyWithScrollAwayElement = (scrollAwayElementHeight: number) => {
  const position = useScroll();
  const containerElementScrollOffsetY = position?.top ?? 0;

  const shouldStick =
    containerElementScrollOffsetY > 0 && containerElementScrollOffsetY >= scrollAwayElementHeight;

  const targetContainerStyles: CSSProperties = {
    position: shouldStick ? "sticky" : "relative",
    transform: shouldStick ? `translateY(-${scrollAwayElementHeight}px)` : "none",
  };

  return {
    targetContainerStyles,
  };
};
